import React from 'react'
import { Title } from '../../components/UI/Title'
import { Subtitle } from '../../components/UI/Subtitle'

export const SPDIvsTrustContent = () => {
  return (
    <div>

      <article tw="mt-10 space-y-6">
        <Title>
          How is a Special Purpose Depository Institution (SPDI) 
          compared/different to a Trust Company (TC)
        </Title>
        <p>
          A Wyoming-chartered SPDI is a fully reserved bank that receives 
          deposits and conducts other activity related to the business of 
          banking, including custody, asset servicing, fiduciary asset 
          management, and related activities. SPDIs can handle digital 
          assets, such as virtual currencies, digital securities, and 
          digital consumer assets. SPDIs may deal in traditional assets as 
          well, by serving as a vehicle for business cash management, 
          operational accounts, and any other purpose permitted under 
          applicable law. 
        </p>
        <p>
          A TC is a separate corporate entity owned by a bank or other 
          financial institution, law firm, or independent partnership. 
          Its function is to manage trusts, trust funds, and estates for 
          individuals, businesses, and other entities. A trust is an 
          arrangement that allows a third party or trustee to hold assets 
          or property for a beneficiary or beneficiaries. TCs get 
          their title from the fact that they act in a fiduciary capacity 
          for their clients—as trustees. 
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          Differences
        </Subtitle>
        <p>
          SPDIs and TCs are different in multiple ways. Important 
          differences include:
        </p>
        <ol tw="list-decimal ml-10">
          <li>
            SPDIs are regulated as banks and must comply with banking 
            regulations and special, strict regulations applicable to SPDIs 
            (for example, have their customer deposits of fiat currency 
            at all times backed 100% or more by unencumbered liquid assets, 
            which include U.S. currency and level 1 high-quality liquid 
            assets).
          </li>
          <li>
            SPDIs advance qualified custody of securities and digital assets. 
            SPDIs may provide custodial services for digital assets and 
            perform authorized transactions for customers. Further, the 
            Wyoming SPDI laws mean that only SPDIs can provide an effective 
            bailment while permitting direct ownership of securities, all 
            provable through the blockchain. 
          </li>
          <li>
            SPDIs may conduct activity under Wyoming regulations tailored to 
            digital assets, which address issues such as technology controls, 
            transaction handling, and custody operations for digital assets.
          </li>
          <li>
            SPDIs operate under Wyoming law that defines digital assets in 
            conjunction with the Wyoming Uniform Commercial Code and describes, 
            among things, perfection and priority of security interests in 
            digital assets. 
          </li>
          <li>
            SPDIs may resemble custody banks because they will likely be 
            predominantly engaged in custody, safekeeping, and asset servicing 
            activities. A custody bank is focused on safekeeping assets, 
            fiduciary management, transaction processing and settlement, and 
            providing an “on/off” ramp to securities markets, commodities 
            markets, and customer bank accounts.
          </li>
          <li>
            A SPDI can engage in a wider range of activities than a TC.
          </li>
          <li>
            A TC is often owned by another entity, such as a bank.
          </li>
        </ol>
        <br />
        <br />
        <p>
          Doug Park, Commercium Legal Advisor
          April 30, 2023
        </p>
      </article>

    </div>
  )
}
